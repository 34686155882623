import { useMonitor } from './useMonitor'

export interface Event {
  event: string;
  data?: Record<string, unknown>;
}
export interface ErrorEvent extends Event {
  event: string;
  error?: Error;
  data?: Record<string, unknown>;
}

export const useEventTracking = (group: string, event?: unknown) => {
  const awsRumClient = useMonitor();
  // TODO: we should use ui-utils event tracker
  function uiUtilsTrackEvent(event: unknown) {
    // eslint-disable-next-line no-console
    console.log('EVENT-TRACKER:', event);
  }

  const trackError = (props: ErrorEvent) => {
    uiUtilsTrackEvent({
      group,
      data: {
        ...props.data,
        event,
      },
      error: props.error,
      event: getEvent(props.event),
    });
    if (!props.error) {
      props.error = new Error('Message: Something went wrong');
    }
    props.error.message = `Group: ${group} - ${props.error.message} - Event: "${getEvent(props.event)}"`;
    awsRumClient.recordError(props.error);
  };
  const trackEvent = (props: Event) => {
    uiUtilsTrackEvent({
      group,
      data: {
        ...props.data,
        event,
      },
      event: getEvent(props.event),
    });
  };

  return { trackEvent, trackError };
};

const getEvent = (title: string) => `SEM - ${title}`;
