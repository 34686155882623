import { Flex } from '@applyboard/crystal-ui'
import { Action } from 'src/clients'
import { useProfile } from 'src/hooks'
import { hasPermission } from 'src/utils/auth'
import { NavBars } from '../../components/NavBars'
import {
  UserManagementHeading,
  UserManagementTable,
} from '../../components/UserManagementTable'

export function UserManagementPage(): JSX.Element {
  const { profileData, isGetProfileLoading } = useProfile()

  return (
    <NavBars selectedMenuItem="All Users">
      <Flex p={{ xs: 4, sm: 12 }} direction="column" grow={1}>
        <UserManagementHeading
          showAddUserButton={
            !isGetProfileLoading &&
            profileData !== undefined &&
            hasPermission(profileData.userType, Action.CREATE)
          }
        />
        <UserManagementTable
          currentSessionUser={profileData}
          isGetProfileLoading={isGetProfileLoading}
        />
      </Flex>
    </NavBars>
  )
}
