import { useQuery } from 'react-query'
import { SchoolData } from '../../clients'

import { UserSession } from '../../auth'
import { useConfig } from '../config'

/**
 * Hook to get current logged in users school data.
 */
export function useSchool() {
  const config = useConfig()

  const { isLoading, data, isError } = useQuery(
    ['school'],
    async () => {
      const userSession = new UserSession(config)
      const payload = userSession.getAuthTokenPayload()
      const schoolId = payload['schoolId']
      if (schoolId === undefined) {
        throw new Error(`Payload schoolId undefined.`)
      }
      const schoolName = payload['schoolName']
      if (schoolName === undefined) {
        throw new Error(`Payload schoolName undefined.`)
      }
      return {
        id: schoolId,
        schoolName,
      } as SchoolData
    },
    {
      enabled: new UserSession(config).isActiveSession(),
    }
  )

  return {
    schoolData: data,
    isGetSchoolLoading: isLoading,
    isGetSchoolError: isError,
  }
}
