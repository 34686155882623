export function FourTwoTwo({ customMessage }: { customMessage?: string }) {
  return (
    <>
      <h2>ERROR 422</h2>
      <h1>Change Rejected</h1>
      <p>Maybe you tried to change something you didn&apos;t have access to?</p>
      {customMessage && <p>{customMessage}</p>}
    </>
  );
}
