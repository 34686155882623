import { Flex, Heading, Text } from '@applyboard/crystal-ui'
import { Application } from 'src/clients'
import { useProfile } from '../../hooks'
import { useApplications } from '../../hooks/application/useApplications'
import { LoadingPage } from '../../pages/LoadingPage'
import { AppCard } from '../AppCard/AppCard'

export function AppDashboard() {
  const { profileData, isGetProfileLoading } = useProfile()
  const { allApplicationsData, isApplicationsLoading } = useApplications()

  const userProfileApps = allApplicationsData?.filter((item) =>
    profileData?.apps.includes(item.name)
  )
  const remainingApps = allApplicationsData?.filter(
    (item) => !profileData?.apps.includes(item.name)
  )

  const dataIsResolved =
    !isGetProfileLoading &&
    !isApplicationsLoading &&
    userProfileApps &&
    remainingApps

  const generateAppCards = (
    appCards: Array<Application>,
    isAvailable: boolean
  ) => {
    return appCards.length > 0 ? (
      <Flex gap={5} wrap>
        {appCards.map((app) => (
          <Flex basis={{ xs: '100%', sm: '400px' }}>
            <AppCard key={app.name} app={app} available={isAvailable} />
          </Flex>
        ))}
      </Flex>
    ) : (
      <Text variant="labelS">Nothing to show</Text>
    )
  }

  return (
    <Flex py={2} gap={5} justify="between">
      <Flex direction="column" gap={6}>
        <Flex>
          <Text variant="headlineM">{`Welcome, ${profileData?.givenName}`}</Text>
        </Flex>
        <Heading level={3} variant="titleS">
          Your available apps
        </Heading>
        <Flex direction="column" gap={6} justify="between" align="stretch">
          {dataIsResolved ? (
            <>
              {generateAppCards(userProfileApps, true)}
              {/* <Heading level={3} variant="titleS">
                Coming soon
              </Heading>
              {generateAppCards(remainingApps, false)} */}
            </>
          ) : (
            <LoadingPage />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
