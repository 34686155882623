import config from './config.json'

export type Config = {
  hostedZoneName: string
  amsHostedZoneName: string
  enrolmentPlannerHostedZoneName: string
}

export function getConfig(): Config {
  return {
    hostedZoneName: config.hostedZoneName,
    amsHostedZoneName: config.amsHostedZoneName,
    enrolmentPlannerHostedZoneName: config.enrolmentPlannerHostedZoneName,
  }
}
