import { Flex } from '@applyboard/crystal-ui'
import { PropsWithChildren, useState } from 'react'

import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useProfile, useSchool } from '../../hooks'
import { LoadingPage } from '../../pages/LoadingPage'
import { Footer } from '../Footer'
import { Header } from './Header'
import { MenuItemLabel, Sidebar } from './Sidebar'

export type NavBarProps = PropsWithChildren & {
  selectedMenuItem: MenuItemLabel
  addFooter?: boolean
}

export function NavBars(props: NavBarProps) {
  const navigate = useNavigate()
  const [isDesktopSidebarOpen, setDesktopSidebarOpen] = useState(false)
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false)

  const { isGetProfileLoading, profileData, isGetProfileError } = useProfile()
  const { schoolData } = useSchool()

  const showNavBars = !isGetProfileLoading && profileData

  if (isGetProfileError) {
    // TODO: determine error behaviour if we fail to get profile data, maybe log them back out?
    navigate('/404')
  }

  return showNavBars ? (
    <Flex direction="column" basis="100%">
      <Header
        profile={profileData}
        school={schoolData}
        desktopSidebarChange={() => {
          setMobileSidebarOpen(false)
          setDesktopSidebarOpen(!isDesktopSidebarOpen)
        }}
        mobileSidebarChange={() => {
          setDesktopSidebarOpen(false)
          setMobileSidebarOpen(!isMobileSidebarOpen)
        }}
      />
      <Flex>
        <Sidebar
          profile={profileData}
          isDesktopSidebarOpen={isDesktopSidebarOpen}
          isMobileSidebarOpen={isMobileSidebarOpen}
          onMobileSidebarOpenChange={setMobileSidebarOpen}
          selectedMenuItem={props.selectedMenuItem}
        />
        <Main>
          <ChildWrapper>{props.children}</ChildWrapper>
          {props.addFooter && <Footer />}
        </Main>
      </Flex>
    </Flex>
  ) : (
    <LoadingPage />
  )
}

const Main = styled.main({
  paddingTop: 64,
  paddingLeft: 0,
  '@media (min-width: 600px)': {
    paddingLeft: 64,
  },
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  minHeight: '100vh',
})

const ChildWrapper = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
})
