import { Box, Button, Flex, Text } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'

import { MenuOutlineIcon } from '@applyboard/ui-icons'
import { Link } from 'react-router-dom'
import { ProfileData, SchoolData } from '../../clients'
import { HeaderMenu } from '../HeaderMenu/HeaderMenu'

type HeaderProps = {
  profile: ProfileData
  school: SchoolData | undefined
  desktopSidebarChange: () => void
  mobileSidebarChange: () => void
}

export function Header(props: HeaderProps) {
  return (
    <HeaderWrapper>
      <Flex
        justify="between"
        align="center"
        py={{ xs: 2, sm: 1 }}
        px={{ xs: 8, sm: 1 }}
        gap={2}
      >
        <Flex align="center" grow={{ xs: 1, sm: 0 }} gap={4}>
          <Flex hideBelow="sm">
            <Button
              onClick={props.desktopSidebarChange}
              aria-label="Main Menu"
              leadIcon={MenuOutlineIcon}
              intent="primary"
            />
          </Flex>
          <Link to="/">
            <Box pt={1}>
              <img alt="ApplyBoard Logo" src="/applyboard-logo-white.svg" />
            </Box>
          </Link>
        </Flex>
        <Flex hideAbove="sm">
          <Button
            onClick={props.mobileSidebarChange}
            aria-label="Main Menu"
            leadIcon={MenuOutlineIcon}
            intent="primary"
          />
        </Flex>
        <Flex hideBelow="sm">
          <HeaderMenu profileData={props.profile} />
        </Flex>
      </Flex>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.actionPrimary,
  zIndex: 1720,
  position: 'fixed',
  width: '100%',
}))
