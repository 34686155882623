import React from 'react';
import { useConfig } from '../config';
import { AwsRum } from 'aws-rum-web';

export type MonitorState = {
  recordError: (error: Error | ErrorEvent) => void;
  recordEvent: (eventType: string, eventData: object) => void;
};

const monitorContext = React.createContext<MonitorState | undefined>(undefined);
export function useMonitor() {
  const monitorState = React.useContext(monitorContext);
  if (!monitorState) throw new Error('useMonitor should be used within <Monitor/>');
  return monitorState;
}

export function Monitor(props: { children: React.ReactNode }) {
  const { dashboardMonitor: rum } = useConfig();

  const value = React.useMemo<MonitorState>(() => {
    const rumRef = rum ? new AwsRum(rum.appMonitorId, '1.0.0', rum.region, rum.config) : undefined;
    return {
      recordError: (error: Error | ErrorEvent) => {
        if (rumRef) {
          rumRef.recordError(error);
        }
      },
      recordEvent: (eventType: string, eventData: object) => {
        if (rumRef) {
          rumRef.recordEvent(eventType, eventData);
        }
      },
    };
  }, [rum]);
  return <monitorContext.Provider value={value}>{props.children}</monitorContext.Provider>;
}
