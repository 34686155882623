import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { CrystalProvider } from '@applyboard/crystal-ui'

import { QueryClient, QueryClientProvider } from 'react-query'
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute'
import { LOGIN_REDIRECT_URI } from './constants'
import { ConfigProvider } from './hooks/config'
import { AddProfilePage } from './pages/AddProfilePage'
import { DashboardPage } from './pages/DashboardPage/DashboardPage'
import { EditProfilePage } from './pages/EditProfilePage'
import { LoginPage } from './pages/LoginPage'
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage'
import { SetPasswordPage } from './pages/SetPasswordPage'
import { UserManagementPage } from './pages/UserManagementPage'
import { Monitor } from './hooks/useMonitor'
import { ErrorElement } from './pages/ErrorPage'
import { ErrorBoundaryWrapper } from './components/ErrorBoundary'

const queryClient = new QueryClient()

function App() {
  return (
    <CrystalProvider>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider>
          <Monitor>
            <ErrorBoundaryWrapper>
              <BrowserRouter>
                <Routes>
                  <Route element={<ProtectedRoute />} errorElement={<ErrorElement />}>
                    <Route index element={<DashboardPage />} errorElement={<ErrorElement />} />
                    <Route path="/profiles" element={<UserManagementPage />} errorElement={<ErrorElement />} />
                    <Route path="/profiles/add" element={<AddProfilePage />} errorElement={<ErrorElement />} />
                    <Route path="/profiles/:id" element={<EditProfilePage />} errorElement={<ErrorElement />} />
                  </Route>
                  <Route element={<ProtectedRoute isSignupOrLoginRoute />} errorElement={<ErrorElement />}>
                    <Route path={LOGIN_REDIRECT_URI} element={<LoginPage />} errorElement={<ErrorElement />} />
                  </Route>
                  <Route path="/confirm" element={<SetPasswordPage />} errorElement={<ErrorElement />} />
                  <Route path="*" element={<NotFoundPage />} errorElement={<ErrorElement />} />
                </Routes>
              </BrowserRouter>
            </ErrorBoundaryWrapper>
          </Monitor>
        </ConfigProvider>
      </QueryClientProvider>
    </CrystalProvider>
  )
}
export default App
