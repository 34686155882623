import { ProfileResource } from '@backend/platform-api/resources/profile'
import { SchoolResource } from '@backend/platform-api/resources/schools'
import { GetFrontendConfigResponse } from '@backend/platform-api/routes/frontend-config/GET/types'

export type ProfileData = ProfileResource['attributes'] & {
  id: string
  schoolId: string
}

export type SchoolData = SchoolResource['attributes'] & {
  id: string
}

export enum Action {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum UserType {
  owner = 'owner',
  admin = 'admin',
  staff = 'staff',
}

export type EditProfileProps = {
  id: string
  profileData: Partial<ProfileResource['attributes']>
}

export type CreateProfileProps = ProfileResource['attributes']

export enum App {
  ApplySolutions = 'ApplySolutions',
  TrainHub = 'TrainHub',
  AMS = 'AMS',
  EnrolmentPlanner = 'EnrolmentPlanner',
}

export type ConfirmPasswordParams = {
  username: string
  temporaryPassword: string
  newPassword: string
}

export type Config = GetFrontendConfigResponse['data']['attributes']

export type IsUserForceChangePasswordParams = {
  username: string
  temporaryPassword: string
}
